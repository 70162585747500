import React, { useState, useContext } from 'react';
import { CartContext } from './CartContext';
import { SauceModal } from './SauceModal';
import { Card, CardBody, CardGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export const SauceCard = ({ sauce }) => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

  return (
        <Card className='sauce-card mb-4 mx-2'>
            <CardBody className='px-1'  onClick={toggle}>
            <h2 style={{ fontWeight: 'bold' }}>{sauce.name}</h2>
            <img src={sauce.image} alt={`${sauce.image}-bottle`} className='sauce-image' />
            <p>
                {sauce.description}
            </p>
            </CardBody>
            <SauceModal modal={modal} toggle={toggle} sauce={sauce} />
        </Card>
  )
}
