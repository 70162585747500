import React, { useState, useContext } from 'react';
import { CartContext } from './CartContext';
import { SauceCard } from './SauceCard';
import { CardGroup } from 'reactstrap';
import { SauceModal } from './SauceModal';

const Sauces = () => {
  const [modal, setModal] = useState(false);
  const { cartItems, updateQuantity, isSecretStash } = useContext(CartContext);

  const toggle = () => setModal(!modal);

  return (
    <div id='sauces'>
        <h1 className='section-header pt-5 mx-2'>{isSecretStash ? 'Shop' : 'Sauces'}</h1>
          {isSecretStash && <p>Sauces are $8 each, and every 3 gets you $4 off! Click a flavor to order.</p> }
          <CardGroup>
            {cartItems.map((sauce) => <SauceCard sauce={sauce} key={sauce.id} />)}
          </CardGroup>
          <SauceModal modal={modal} toggle={toggle} />
          </div>
  );
};

export default Sauces;
