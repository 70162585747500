import React, { useState, useContext } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  NavbarText
} from 'reactstrap';
import { CartContext } from './CartContext'; // Import CartContext
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'; // Import the shopping cart icon
import { CartModal } from './CartModal';

function DrBellyNavbar(args) {
  const [isOpen, setIsOpen] = useState(false);
  const { cartItems, isSecretStash, setCompletedPurchase } = useContext(CartContext); // Access cartItems from CartContext

  const toggle = () => setIsOpen(!isOpen);
  const closeMenu = () => isOpen && setIsOpen(false);
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    if (!modal === false) {
      setCompletedPurchase(false);
    }
    setModal(!modal);
  }
  // Calculate total number of items in the cart
  const totalItems = cartItems.reduce((sum, item) => sum + item.quantity, 0);

  return (
    <div>
      <Navbar {...args} fixed='top'>
        <NavbarBrand href="#">Dr. Belly's</NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="me-auto" navbar>
            <NavItem>
              <NavLink href="#sauces" onClick={closeMenu}>{isSecretStash ? 'Shop' : 'Sauces'}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#about" onClick={closeMenu}>About</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#locations" onClick={closeMenu}>Locations</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#contact" onClick={closeMenu}>Contact</NavLink>
            </NavItem>
          </Nav>
          {isSecretStash && (
          <NavbarText>
            <NavLink href="#cart" onClick={toggleModal}>
              <FontAwesomeIcon icon={faShoppingCart} /> ({totalItems})
            </NavLink>
          </NavbarText>
          )}
        </Collapse>
        <CartModal modal={modal} toggle={toggleModal} />
      </Navbar>
    </div>
  );
}

export default DrBellyNavbar;
