import React, { useContext, useState } from 'react';
import { CartContext } from './CartContext';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import ThankYou from './ThankYou';
const Cart = () => {
  const { cartItems, updateQuantity, isLocal, redirect, completedPurchase, setCompletedPurchase } = useContext(CartContext);
  const [orderInfo, setOrderInfo] = useState({
    id: '',
    payer: {
      name: { given_name: '', surname: '' }
    },
    email_address: '',
  });
  const paypalId = isLocal ? process.env.REACT_APP_PAYPAL_SANDBOX_ID : process.env.REACT_APP_PAYPAL_CLIENT_ID;

  // Get total quantity of items in the cart
  const totalQuantity = cartItems.reduce((total, item) => total + item.quantity, 0);
  const shippingCost = totalQuantity > 0 ? 7.00 : 0;
  // Total three packs should be the total quantity divided by 3 and rounded down
  const totalThreePacks = Math.floor(totalQuantity / 3);
  // Total three pack discount should be the total number of three packs multiplied by 4
  const totalThreePackDiscount = totalThreePacks * 4;
  // Calculate total price, if the total quantity of items is 3 or more, apply a $4 discount
  const priceOfItems = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  const totalPrice = priceOfItems - totalThreePackDiscount + 7;
  
  // PayPal Buttons options
  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          description: "Hot Sauce Cart",
          amount: {
            currency_code: "USD",
            value: (totalPrice).toFixed(2), // Total value with shipping and discount
            breakdown: {
              item_total: {
                currency_code: "USD",
                value: priceOfItems.toFixed(2), // Total of items only
              },
              shipping: {
                currency_code: "USD",
                value: shippingCost.toFixed(2), // Shipping cost
              },
              discount: {
                currency_code: "USD",
                value: totalThreePackDiscount.toFixed(2), // Discount value
              },
            },
          },
          items: [
            ...cartItems
              .filter((item) => item.quantity > 0)
              .map((item) => ({
                name: item.name,
                unit_amount: {
                  currency_code: "USD",
                  value: item.price.toFixed(2),
                },
                sku: item.id,
                quantity: item.quantity,
              })),
          ],
        },
      ],
    });
  };
  

  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      cartItems.forEach((item) => updateQuantity(item.id, 0));
      setOrderInfo(details);
      setCompletedPurchase(true);
    });
  };

 const onError = (err) => {
  alert(err);
}
  const getItemTotal = (item) => {
    return (item.price * item.quantity).toFixed(2);
  };

  const renderCart = () => {
  return (
    <div>
      <h2>Your Cart</h2>
      <div className='cart-item'>
        <span className='item-quantity'>Item</span>
        <span className='item-quantity'>Quantity</span>
        <span className='item-total'>Total</span>
      </div>
      {totalQuantity === 0 ? <h3>Your cart is empty</h3> :
      <div>
      {cartItems.filter(item => item.quantity > 0).map((item) => (
        <div key={item.id} className='cart-item'>
          <span className='item-quantity'>{item.name}</span>
          <span className='item-quantity'>{item.quantity}</span>
          <span className='item-total'>${getItemTotal(item)}</span>
        </div>
      ))}
      {totalQuantity >= 3 && (
        <div className='cart-item'>
          <span className='item-quantity'>Discount</span>
          <span className='item-quantity'></span>
          <span className='item-total'>-${totalThreePackDiscount.toFixed(2)}</span>
        </div>
      )}
      <div className='cart-item'>
        <span className='item-quantity'>Shipping</span>
        <span className='item-quantity'></span>
        <span className='item-total'>${shippingCost.toFixed(2)}</span>
      </div>
      <h3>Total: ${totalPrice.toFixed(2)}</h3>
      </div>
}

      <PayPalScriptProvider
        options={{
          clientId: paypalId,
          currency: 'USD',
        }}
      >
      {totalQuantity > 0 && (
        <PayPalButtons
          createOrder={createOrder}
          onApprove={onApprove}
          onError={onError}
          className='paypal-buttons'
        />
      )}
      </PayPalScriptProvider>
    </div>
  )
};

const renderThankYou = () => {
  return <ThankYou name={orderInfo.payer.name.given_name} orderNumber={orderInfo.id} />;
}

return (
  <div>
    {completedPurchase ? renderThankYou() : renderCart()}
  </div>
);
};

export default Cart;
