import React, { useContext } from 'react';
import { CartContext } from './CartContext';

import { Modal, ModalHeader, ModalBody} from 'reactstrap';

import Cart from './Cart';

export const CartModal = ({modal, toggle}) => {
  return (
    <Modal isOpen={modal} toggle={toggle} size='lg' centered='true' fullscreen={true}>
      <ModalHeader toggle={toggle}>Cart</ModalHeader>
      <ModalBody className='big m-auto cart-modal'>
        <Cart />
      </ModalBody>
    </Modal>
  );
}
