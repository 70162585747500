import React from 'react';

const ThankYou = ({ name, orderNumber }) => {
    return (
        <div className='thank-you'>
            <h1>Thank You {name}!</h1>
            <p>Your payment has been successfully processed.</p>
            <p>Your order number is: {orderNumber}</p>
            <p>Check your email for confirmation</p>
        </div>
    );
};

export default ThankYou;
