import React, { createContext, useState } from 'react';
import hellBottle from '../assets/hellWeb.png';
import suluBottle from '../assets/suluWeb.png';
import fireBottle from '../assets/fireWeb.png';
import firePeppers from '../assets/fire-peppers.png';
import hellPeppers from '../assets/hell-peppers.png';
import suluPeppers from '../assets/sulu-peppers.png';

// Create a CartContext
export const CartContext = createContext();
const emptyCart = [
  { 
    id: 1, 
    name: "Fire Tonic", 
    description: "A fiery habanero garlic blend with a sweet touch.",
    image: fireBottle,
    peppers: firePeppers,
    suggestions: 'Goes well with: tortilla chips, chicken, tacos',
    subDescription: "A fan-favorite, Fire Tonic will scratch that itch for a little pain with your pleasure.",
    price: 8.0, 
    quantity: 0 
  },
  {
    id: 2,
    name: "Sulu Sauce",
    description: "An emulsified jalapeno-based hot sauce.",
    image: suluBottle,
    peppers: suluPeppers,
    subDescription: "With its thick creamy texture, Sulu Sauce is a must for chip dippin' as well as being slathered all over chicken or beef. Go ahead, dump it on everything.",
    suggestions: 'Goes well with: tortilla chips, chicken, tacos',
    price: 8.0,
    quantity: 0
  },
  { id: 3, 
    name: "Taco Hell", 
    description: "This cumin-heavy classic is a scorcher.",
    subDescription: "Well well well, look at you checking out the hottest one. Well I gotta say, this one offers a lot more than just heat. It's great for fans of that southwestern flavor, but you might want a glass of milk...",
    suggestions: 'Goes well with: tacos, burritos, eggs',
    image: hellBottle,
    peppers: hellPeppers,
    price: 8.0, 
    quantity: 0 }
];
export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    const savedCart = localStorage.getItem('cart');
    if (savedCart) {
      return JSON.parse(savedCart);
    }
    localStorage.setItem('cart', JSON.stringify(emptyCart));
    return emptyCart;
    }
  );

  // Function to update the quantity of an item
  const updateQuantity = (id, quantity) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, quantity: quantity } : item
      )
    );
  };

  const isLocal = window.location.href.includes("localhost:3000");
  const isSecretStash = window.location.href.includes("secretStash") || 
  window.location.href.includes("secretstash") || 
  window.location.href.includes("SecretStash") ||
  isLocal;

  const [showToast, setShowToast] = useState(false);
  const [completedPurchase, setCompletedPurchase] = useState(false);
  const updateShowToast = () => {
    !showToast && setShowToast(true);
  }
  return (
    <CartContext.Provider value={{ cartItems, updateQuantity, showToast, updateShowToast, setShowToast, isSecretStash, isLocal, completedPurchase, setCompletedPurchase }}>
      {children}
    </CartContext.Provider>
  );
};
