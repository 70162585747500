import React from 'react';

const About = () => {
  return (
    <div className='mx-2' id='about'>
        <h1 className='section-header pt-5'>About us</h1>
        <h4 className='text-start'>We are a local hot sauce company created by a couple of hot sauce fanatics who were tired of compromising flavor for heat. Dr. Belly’s offers three flavors, ranging from mild spice to hot, but always prioritizing tastiness. With hot sauces that pair well with just about any dish, there truly is a Dr. Belly for all of us.</h4>
    </div>
  );
};

export default About;
