import './App.css';
import SplashPage from './SplashPage';
import DrBellyNavbar from './components/DrBellyNavbar';
import DrBellyFooter from './components/DrBellyFooter';
import { CartProvider } from './components/CartContext';
function App() {
  return (
    <CartProvider>
    <div className="App">
      <DrBellyNavbar color="dark" dark expand="md" />
        <SplashPage />
      <DrBellyFooter color="dark" dark expand="md" />
    </div>
    </CartProvider>
  );
}

export default App;
