import React, { useContext, useState } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import { CartContext } from './CartContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

export const SauceModal = ({ modal, toggle, sauce }) => {
  const { updateQuantity, setShowToast, updateShowToast, isSecretStash } = useContext(CartContext);

  if (!sauce) {
    return null; // No sauce to show
  }

  const handleQuantityChange = (e) => {
    updateQuantity(sauce.id, parseInt(e.target.value) || 0);
  };

  const handleUpdateCart = () => {
    toggle(); // Close the modal
    updateShowToast(); // Show success toast
    setTimeout(() => setShowToast(false), 3000); // Hide toast after 3 seconds
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} size='lg' centered={true} fullscreen={true}>
        <ModalHeader toggle={toggle}>{sauce.name}</ModalHeader>
        <ModalBody className='jumbo big'>
          <Row>
            <Col>
              <img src={sauce.image} alt={`${sauce.image}-bottle`} className='sauce-modal-image' />
            </Col>
            <Col className='text-start'>
              <h2 style={{ fontWeight: 'bold', textAlign: 'left' }}>{sauce.name}</h2>
              <h4 className='text-start'>{sauce.subDescription}</h4>
              <p>{sauce.suggestions}</p>
              <img src={sauce.peppers} alt={`${sauce.image}-peppers`} className='peppers-image mt-2' />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
        {isSecretStash && (
          <>
          <label htmlFor={`quantity-${sauce.id}`} className="mr-2">Quantity:</label>
          <Input
            type="select"
            id={`quantity-${sauce.id}`}
            value={sauce.quantity}
            onChange={handleQuantityChange}
            className="quantity"
            min={0}
            max={10}
          >
            {Array.from({ length: 11 }, (_, i) => (
              <option key={i} value={i}>{i}</option>
            ))}
          </Input>
          <Button color="success" onClick={handleUpdateCart}>
            <FontAwesomeIcon icon={faShoppingCart} className="mr-2" />
            Update cart
          </Button>
          </>
          )}
        </ModalFooter>
      </Modal>

      {/* Success Toast */}

    </>
  );
};
